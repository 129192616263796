import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app.component";
import { initializeI18n } from "@fraktion/core-frontend";
import "@fraktion/core-frontend/dist/style.css";
import "./core.config";
import { deploymentProvider } from "@/config/deployment";

// initializeI18n();

// ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

async function initializeApp() {
  // Initialize i18n first as it's needed for the UI
  initializeI18n();

  try {
    // Then initialize Amplify
    await deploymentProvider.initialize();

    // Only render the app after both initializations are complete
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error("Failed to initialize the application:", error);
  }
}

initializeApp();
